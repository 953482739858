<template>
  <v-card rounded="10">
    <v-card
      elevation="3"
      flat
      rounded="0"
    >
      <v-card-text class="container-general-title">
        <div />

        <div class="container-title">
          <v-icon
            class=""
            @click="closeModal()"
          >
            {{ icons.mdiCashPlus }}
          </v-icon>

          <span>Relatório {{ typeReport }}</span>
        </div>

        <v-icon
          class=""
          @click="closeModal()"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-text>

      <v-card-text class="container-action-add-center">
        <v-btn
          color="info"
          outlined
          @click="openModalAddCenter = true"
        >
          <v-icon
            class=""
            @click="closeModal()"
          >
            {{ icons.mdiPlaylistPlus }}
          </v-icon>
          NOVO CENTRO
        </v-btn>
      </v-card-text>

      <v-card-text>
        <v-data-table
          :items="listReportTypes"
          :headers="headers"
          :loading="loading"
          loading-text="Carregando dados..."
        >
          <template v-slot:item.action="{ item }">
            <v-icon
              v-if="item.id === loadingSpinner ? false : true"
              medium
              class="me-2"
              color="error"
              @click="handleDeleteDataById(item.id)"
            >
              {{ icons.mdiTrashCanOutline }}
            </v-icon>

            <v-progress-circular
              v-if="item.id === loadingSpinner ? true : false"
              indeterminate
              :size="25"
              color="info"
            ></v-progress-circular>
          </template>

          <template v-slot:item.created_at="{ item }">
            {{ dateFormat(item.created_at) }}
          </template>
        </v-data-table>
      </v-card-text>

      <v-dialog
        v-model="openModalAddCenter"
        width="550"
        persistent
      >
        <v-card
          elevation="3"
          flat
          rounded="0"
        >
          <v-card-text class="container-general-title">
            <div />

            <div class="container-title">
              <span>Novo centro</span>
            </div>

            <v-icon
              class=""
              @click="closeModalAddCenter()"
            >
              {{ icons.mdiClose }}
            </v-icon>
          </v-card-text>

          <v-card-text>
            <separator label="Criar novo centro de resultados" />
          </v-card-text>

          <v-card-text>
            <v-autocomplete
              v-model="centerResult"
              class="mt-4"
              :items="listExpanses"
              item-text="description"
              item-value="description"
              label="Centro de resultados"
              dense
              outlined
            ></v-autocomplete>

            <v-text-field
              v-model="centerTyped"
              outlined
              dense
              label="Centro de resultados específico"
            />
          </v-card-text>

          <v-card-text>
            <div class="actions-add-center">
              <v-btn
                color="error"
                @click="openModalAddCenter = false"
              >
                Cancelar
              </v-btn>

              <v-btn
                color="info"
                @click="handleCreateCenter"
              >
                <v-progress-circular
                  v-if="loadingAddCenterResult"
                  indeterminate
                  :size="25"
                  color="white"
                ></v-progress-circular>
                <span v-else>Criar</span>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-card>
</template>
<script>
import Separator from '@/components/Separator.vue'
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiCashPlus,
  mdiClose,
  mdiPlaylistPlus,
  mdiTrashCanOutline,
} from '@mdi/js'

export default {
  components: {
    Separator,

    // NoDataInterface,
  },
  mixins: [messages, formatters],
  props: {
    typeReport: {
      type: String,
      required: true,
    },
    typeReportSelected: {
      type: String,
      required: true,
    },
    numberReport: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      centerTyped: '',
      loadingAddCenterResult: false,
      centerResult: '',
      openModalAddCenter: false,
      loadingSpinner: '',
      loading: false,
      listExpanses: [],
      listReportTypes: [],
      icons: {
        mdiClose,
        mdiCashPlus,
        mdiPlaylistPlus,
        mdiTrashCanOutline,
      },
      headers: [
        { text: 'CENTRO DE RESULTADOS', value: 'exception', align: '' },
        { text: 'DATA', value: 'created_at', align: '' },
        { text: 'OPÇÕES', value: 'action', align: 'center' },
      ],
    }
  },
  created() {
    this.handleListExpanses()
    this.handleListReportTypes()
  },
  methods: {
    async handleListExpanses() {
      const responseExpenses = await axiosIns.get('/api/v1/utilities/all_strategy_exception_list/expenses_index')
      const responseRevenue = await axiosIns.get('/api/v1/utilities/all_strategy_exception_list/revenues_index') // revenue

      this.listExpanses = this.numberReport === 0 ? responseRevenue.data.data : responseExpenses.data.data

      // console.log(this.listExpanses)
    },
    async handleListReportTypes() {
      const response = await axiosIns.get(`/api/v1/utilities/all_strategy_exception_list/find_by_report_type_id/${this.numberReport}`)

      // console.log(response.data.data)
      this.listReportTypes = response.data.data
    },

    async handleDeleteDataById(id) {
      this.loadingSpinner = id

      // const body = {
      //   report_type_id: Number(reportTypeId),
      //   record_id: id,
      // }

      try {
        await axiosIns.delete(`/api/v1/utilities/all_strategy_exception_list/delete_by_report_id/${id}`)
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao deletar os dados!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.loadingSpinner = ''
        this.handleListReportTypes()
      }
    },
    closeModalAddCenter() {
      this.openModalAddCenter = false
    },

    async handleCreateCenter() {
      this.loadingAddCenterResult = true

      const body = {
        exception: this.centerResult,
        report_type_id: this.numberReport,
      }

      const bodyTyped = {
        description: this.centerTyped,
      }

      const urls = {
        expenses: '/api/v1/utilities/all_strategy_exception_list/store_one_expense',
        revenues: '/api/v1/utilities/all_strategy_exception_list/store_one_revenue',
      }

      try {
        if (this.centerTyped) {
          await axiosIns.post(urls[this.typeReportSelected], bodyTyped)
        } else {
          await axiosIns.post('/api/v1/utilities/all_strategy_exception_list/store', body)
        }
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao adicionar um centro de resultado!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.loadingAddCenterResult = false
        this.handleListReportTypes()
        this.closeModalAddCenter()
      }
    },
  },
}
</script>
<style scoped>
.container-general-title{
  display: flex;
  justify-content: space-between;
}
.container-title{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.container-title span {
  font-size: 18px;
}

.container-action-add-center{
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
}

.actions-add-center{
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
</style>
