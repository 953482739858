<template>
  <v-card
    class="px-8 py-8 d-flex flex-column"
  >
    <span
      class=""
      style="color: #fff"
    >Centros de resultados ignorados</span>

    <div class="mt-10 contain-content-black-list">
      <div class="card-black-list">
        <span>Relatório outras receitas</span>

        <v-icon
          size="45"
          color="white"
        >
          {{ icons.mdiCashPlus }}
        </v-icon>

        <v-btn
          color="white"
          outlined
          @click="handleOpenModalReport('Receitas', 0)"
        >
          VISUALIZAR
        </v-btn>
      </div>

      <div class="card-black-list">
        <span>Relatório contas pagas</span>

        <v-icon
          size="45"
          color="white"
        >
          {{ icons.mdiCashCheck }}
        </v-icon>

        <v-btn
          color="white"
          outlined
          @click="handleOpenModalReport('Despesas', 1)"
        >
          VISUALIZAR
        </v-btn>
      </div>
    </div>

    <v-dialog
      v-model="openModal"
      width="700"
      persistent
    >
      <modal-black-list
        :key="reportTypeSelected"
        :type-report="reportTypeSelected"
        :type-report-selected="reportTypeSelected === 'Receitas' ? 'revenues' : 'expenses'"
        :number-report="numberReportTypeSelect"
        @close="openModal = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { getVuetify } from '@core/utils'
import {
  mdiCalendar,
  mdiCashCheck,
  mdiCashMinus,
  mdiCashPlus,
  mdiCloudSyncOutline,
  mdiDatabaseArrowDownOutline,
  mdiFilterVariant,
  mdiPlaylistPlay,
} from '@mdi/js'
import moment from 'moment'
import 'moment/locale/pt-br'
import ModalBlackList from './ModalBlackList.vue'

moment.locale('pt-br')

export default {
  components: {
    ModalBlackList,

    // NoDataInterface,
  },
  mixins: [messages, formatters],
  data() {
    return {
      openModal: false,
      reportTypeSelected: '',
      numberReportTypeSelect: '',
      headers: [
        {
          text: 'FILIAL',
          value: 'region',
          sortable: false,
          align: '',
        },
        {
          text: 'LOJA',
          value: 'fantasy_name',
          sortable: false,
          align: '',
        },
        {
          text: 'FUNCIONÁRIO',
          value: 'name',
          sortable: false,
          align: '',
        },
        {
          text: 'COD. SELF',
          value: 'cod_self',
          sortable: false,
          align: '',
        },
      ],

      icons: {
        mdiPlaylistPlay,
        mdiCloudSyncOutline,
        mdiFilterVariant,
        mdiCalendar,
        mdiDatabaseArrowDownOutline,
        mdiCashPlus,
        mdiCashCheck,
        mdiCashMinus,
      },
      isoDate: moment().format('YYYY-MM'),
      menu: false,
      isExporting: false,
      reportSelected: '',
      reportsList: [{ title: 'DRE de Serviços', type: 1 }, { title: 'DRE de Receitas', type: 2 }, { title: 'DRE de Despesas', type: 3 }, { title: 'DRE de Vendas de Veículos', type: 4 }],
      itemTableSelected: [],
      salesListId: [],
      isLoadingTableData: false,
    }
  },
  computed: {
    formattedDate: {
      get() {
        return moment(this.isoDate, 'YYYY-MM').format('MM/YYYY').toUpperCase()
      },
      set(value) {
        if (!value) {
          this.isoDate = value

          return
        }
        this.isoDate = moment(value, 'MMMM YYYY').format('YYYY-MM')
      },
    },
    isDark() {
      const $vuetify = getVuetify()

      return $vuetify.theme.dark
    },
  },
  watch: {

  },
  methods: {
    handleOpenModalReport(reportType, numberReportSelect) {
      this.openModal = true
      this.reportTypeSelected = reportType
      this.numberReportTypeSelect = numberReportSelect
    },

  },
}
</script>

<style scoped>
.contain-content-black-list{
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.card-black-list{
  width: 210px;
  height: 230px;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 24px;
  font-size: 16px;
  color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;
}
</style>
